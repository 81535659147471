/*
  * @Author: 肖芬芳
  * @Date: 2024/05/09 
  * @LastEditors: 肖芬芳
  * @LastEditTime: 2024/05/09 
  * @Description: 
*/

/**
    * ruleTypes: 规则类型
    * genRules： rule 生成函数
    * ctx： Vue实例
    */
// form 默认值


import { genModels } from '@/plugins/widget/c-form/rules';
import {
sequencingDataUnit, libraryType, indexTypeOption, kit } from
'@/utils/options';

//列表信息
export var columns = function columns(ctx) {return [
  {
    type: 'selection',
    width: 60 },

  {
    label: '序号',
    kname: 'index',
    width: 60,
    fixed: 'left' },

  {
    label: '子文库名称',
    kname: 'libraryName' },

  {
    label: '单（双）index',
    kname: 'indexType' },

  {
    label: 'indexF-序列',
    kname: 'indexFSequence' },

  {
    label: 'indexR-序列',
    kname: 'indexRSequence' },

  {
    label: '测序数据量',
    kname: 'sequencingDataAmount' },

  {
    label: '数据量单位',
    kname: 'dataUnit' },

  {
    label: '文库类型',
    kname: 'libraryType' },

  {
    label: 'index类型',
    kname: 'indexTypeOption' },

  {
    label: '建库试剂盒',
    kname: 'kit' },

  {
    label: '备注',
    kname: 'remarks' },

  {
    label: '操作',
    kname: 'btns',
    width: 160,
    fixed: 'right' }];};




//编辑弹框————表单信息
export var models = genModels(function (genRules, ctx) {return [
  {
    span: 10,
    kname: 'libraryName',
    itemProps: {
      label: '文库名称：',
      rules: [genRules('require')] },

    component: {
      name: 'el-input',
      props: {
        maxlength: 50 } } },



  {
    span: 10,
    kname: 'indexType',
    itemProps: {
      label: '单（双）index：',
      rules: [genRules('require')] },

    component: {
      name: 'el-input',
      props: {
        maxlength: 50 } } },



  {
    span: 10,
    kname: 'indexFSequence',
    itemProps: {
      label: 'indexF-序列：',
      rules: [genRules('require')] },

    component: {
      name: 'el-input',
      props: {
        maxlength: 50 } } },



  {
    span: 10,
    kname: 'indexRSequence',
    itemProps: {
      label: 'indexR-序列：',
      rules: [genRules('require')] },

    component: {
      name: 'el-input',
      props: {
        maxlength: 50 } } },



  {
    span: 10,
    kname: 'sequencingDataAmount',
    itemProps: {
      label: '测序数据量：',
      rules: [genRules('require')] },

    component: {
      name: 'el-input',
      props: {
        maxlength: 20 } } },



  {
    span: 10,
    kname: 'dataUnit',
    itemProps: {
      label: '数据量单位：',
      rules: [genRules('require')] },

    component: {
      name: 'selector',
      props: {
        list: sequencingDataUnit,
        clearable: true } } },



  {
    span: 10,
    kname: 'libraryType',
    itemProps: {
      label: '文库类型：',
      rules: [genRules('require')] },

    component: {
      name: 'selector',
      props: {
        list: libraryType,
        clearable: true } } },



  {
    span: 10,
    kname: 'indexTypeOption',
    itemProps: {
      label: 'index类型：',
      rules: [genRules('require')] },

    component: {
      name: 'selector',
      props: {
        list: indexTypeOption,
        clearable: true } } },



  {
    span: 10,
    kname: 'kit',
    itemProps: {
      label: '建库试剂盒：',
      rules: [genRules('require')] },

    component: {
      name: 'selector',
      props: {
        list: kit,
        clearable: true } } },



  {
    span: 20,
    kname: 'remarks',
    itemProps: {
      label: '备注：' },

    component: {
      name: 'el-input',
      props: {
        type: 'textarea',
        maxlength: 50 } } }];});





// form 默认值
export var formData = function formData() {return {};};